import CKNavigator from "../lib/CKNavigator";
import Styles, { CKColors, CKPadding, CKSizes } from '../lib/Styles';
import CommsLayer from "../lib/api/CommsLayer";
import CKSpacer from "../lib/components/CKSpacer";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";
import CompareLevelsImg from '../images/compare_levels.png';
import CKText from "../lib/components/CKText"; 
import {ReactComponent as CompareLevelsCheckWhite} from '../images/compare_levels_check_white.svg';
import {ReactComponent as LogoExtraMembers} from '../images/logo_extra_members.svg';
import { CKStrings } from "../lib/components/CKStrings";
import CKOffer from "../lib/components/CKOffer";
import CKIcon, { CKIconType } from '../lib/components/CKIcon';

export default class ViewMembershipLevels extends LoggedInBase {
    screenName = 'home'
    title = 'Compare levels'
    subLevelPage = true

    constructor(props) {
        super(props);

        let initialExpandedLevel = -1;

        if(props.route && props.route.params && props.route.params.myClub) {
            // lets check if level exist on myClub
            if(props.route.params.myClub.level) {
                initialExpandedLevel = props.route.params.myClub.level - 1;
            }

            if(props.route.params.myClub.isSpecial) {
                initialExpandedLevel = -2;
            }
        }


        this.state = {
            ...this.state,

            offers: [],

            displayingData: [],

            expandedLevel: initialExpandedLevel,

            screenLoading: true
        }
    }

    async loadData() {
        

        await CommsLayer.xmls().levels().then(levels => {
            let nextState = {
                offers: levels.compare && levels.compare.offers ? levels.compare.offers : [],
                image: levels.compare ? levels.compare.image : '',
                headline: levels.compare ? levels.compare.headline : '',
                description: levels.compare ? levels.compare.description : '',
                level_1: levels.tiered && levels.tiered.length > 0 ? levels.tiered[0] : false,
                level_2: levels.tiered && levels.tiered.length > 1 ? levels.tiered[1] : false,
                level_3: levels.tiered && levels.tiered.length > 2 ? levels.tiered[2] : false
            };

            // lets prepare data for all levels
            let displayLevelsData = {
                level_1: {
                    ...nextState.level_1,
                    offers: nextState.offers.filter(o => {
                        return o.level1;
                    }),
                    color: CKColors.orange
                },
                level_2: {
                    ...nextState.level_2,
                    offers: nextState.offers.filter(o => {
                        return o.level2;
                    }),
                    color: CKColors.red
                },
                level_3: {
                    ...nextState.level_3,
                    offers: nextState.offers.filter(o => {
                        return o.level3;
                    }),
                    color: CKColors.red5
                }
            };

            nextState.displayingData = [
                displayLevelsData.level_1,
                displayLevelsData.level_2,
                displayLevelsData.level_3
            ];

            this.setState(nextState);
        })

        this.setState({
            isRefreshing: false,
            screenLoading: false
        });

        
    }

    onBack() {
        this.navigateTo(CKNavigator.home);
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();

        if(this.state.initialExpandedLevel == -2) {
            this.back();
        }
    }

    render() {
        return super.render(
            <View style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <View className="view-membership-level-holder">
                    <img alt={this.state.headline} className="image" src={this.state.image ? this.state.image : CompareLevelsImg} />

                    <View className="content-holder">
                        {this.state.headline ? 
                            <CKText className="title">
                                {this.state.headline}
                            </CKText>
                            : null }
                        {this.state.description ? 
                            <CKText className="description">
                                {this.state.description}
                            </CKText>
                            : null }
                    </View>
                    <View style={{
                        marginTop: CKPadding.default * 1.5,
                        flexDirection: 'row'
                    }}>
                        <View style={{
                            flex: 1
                        }}>
                            {this.state.displayingData.map((data, index) => {
                                return <View key={'level_data_' + index} style={{
                                    marginTop: index != 0 ? CKPadding.minimal : 0
                                }}>
                                    <View activeOpacity={.85} onClick={() => {
                                        this.setState({
                                            expandedLevel: index == this.state.expandedLevel ? -1 : index
                                        })
                                    }}>
                                        <View style={{
                                            backgroundColor: data.color,
                                            paddingHorizontal: CKPadding.default,
                                            paddingVertical: CKPadding.default / 2,
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <CKText style={{
                                                ...Styles.actBlack,
                                                fontSize: CKSizes.font.px18,
                                                color: CKColors.white,
                                                float: 'left',
                                                textAlign: 'left',
                                                lineHeight: '40px',
                                                width: 75
                                            }}>{data.text}</CKText>
                                            <CKText style={{
                                                ...Styles.actMedium,
                                                fontSize: CKSizes.font.px16,
                                                color: CKColors.white,
                                                float: 'left',
                                                lineHeight: '40px',
                                                textAlign: 'left',
                                                flex: 1
                                            }}>{CKStrings.convertToUserCurrency(data.range)}</CKText>
                                            <View style={{ float:'right'}}>
                                                <View style={  this.state.expandedLevel == index ? {                                                
                                                } : {
                                                    transform: [{rotate: '180deg'}]
                                                }}><CKIcon icon={CKIconType.chevron_down} width={24} /></View>
                                            </View>
                                            <View style={{ clear: 'both'}}></View>
                                        </View>
                                    </View>

                                    {this.state.expandedLevel == index ? <View style={{
                                        borderWidth: 1,
                                        borderColor: CKColors.borderColor,
                                        borderTopWidth: 0,
                                        paddingBottom: CKPadding.default
                                    }}>
                                        {data.offers.map(offer => {
                                            let isFuel = offer.icon == 'fuel';

                                            return <View key={offer.title} style={{
                                                backgroundColor: isFuel ? CKColors.offWhite : 'transaprent',
                                                borderRadius: isFuel ? 12 : 0,
                                                margin: CKPadding.minimal,
                                                paddingVertical: CKPadding.minimal / 2,
                                                paddingHorizontal: CKPadding.minimal,
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                ...(!isFuel ? {
                                                    marginTop: CKPadding.minimal - 4,
                                                    marginBottom: CKPadding.minimal - 4
                                                } : {})
                                            }}>
                                                <View style={{
                                                    backgroundColor: CKColors.lightOrange,
                                                    borderRadius: 35,
                                                    width: 35,
                                                    height: 35,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: '8px',
                                                    float: 'left',
                                                    display: 'flex',
                                                    marginRight: CKPadding.minimal
                                                }}>
                                                    <CKOffer scaleIcon={true} iconOnly={true} type={offer.icon} />
                                                </View>
                                                <CKText style={{
                                                    ...Styles.actBook,
                                                    fontSize: CKSizes.font.px16,
                                                    color: CKColors.offBlack,
                                                    textAlign: 'left',
                                                    float: 'left',
                                                    lineHeight: '50px',
                                                    flex: 1
                                                }}>{offer.title}</CKText>

                                                {isFuel ? <View style={{
                                                    backgroundColor: data.color,
                                                    borderRadius: 12,
                                                    height: 50,
                                                    width: 92,
                                                    float: 'right',
                                                    justifyContent: 'center',
                                                    marginRight: -(CKPadding.minimal / 2)
                                                }}>
                                                    <View style={{marginTop:'5px'}}>
                                                    <CKText style={{
                                                        ...Styles.actBlack,
                                                        fontSize: CKSizes.font.default,
                                                        color: CKColors.white,
                                                        textAlign: 'center'
                                                    }}>{CKStrings.convertToUserCurrency(offer['level' + (index + 1)])}</CKText>
                                                    </View>
                                                    <View style={{ marginTop: '-5px'}}>
                                                    <CKText style={{
                                                        ...Styles.actMedium,
                                                        fontSize: CKSizes.font.px10,
                                                        color: CKColors.white,                                                        
                                                        textAlign: 'center'
                                                    }}>off per litre</CKText>
                                                    </View>
                                                </View> : null}

                                                <View style={{ clear: 'both'}}> </View>
                                            </View>
                                        })}
                                    </View> : null}
                                </View>
                            })}
                        </View>

                    </View>
                    <CKSpacer />
                </View>
            </View>
        )
    }
}