import {ReactComponent as IconBack} from '../../images/icon_back.svg';
import {ReactComponent as IconEyeClosed} from '../../images/icon_eye_closed.svg';
import {ReactComponent as IconEyeOpened} from '../../images/icon_eye_opened.svg';
import {ReactComponent as IconEmail} from '../../images/icon_email.svg';
import {ReactComponent as IconScanner} from '../../images/icon_scanner.svg';
import {ReactComponent as IconExtra} from '../../images/icon_extra.svg';
import {ReactComponent as IconExtraOn} from '../../images/icon_extra_on.svg';
import {ReactComponent as IconHome} from '../../images/icon_home.svg';
import {ReactComponent as IconHomeOn} from '../../images/icon_home_on.svg';
import {ReactComponent as IconLoyaltyCard} from '../../images/icon_loyalty_card.svg';
import {ReactComponent as IconLoyaltyCardOn} from '../../images/icon_loyalty_card_on.svg';
import {ReactComponent as IconMore} from '../../images/icon_more.svg';
import {ReactComponent as IconMoreOn} from '../../images/icon_more_on.svg';
import {ReactComponent as IconStores} from '../../images/icon_stores.svg';
import {ReactComponent as IconStoresOn} from '../../images/icon_stores_on.svg';
import {ReactComponent as IconCancel} from '../../images/icon_cancel.svg';
import {ReactComponent as IconCancelGrey} from '../../images/icon_cancel_grey.svg';

import {ReactComponent as IconFeedback} from '../../images/icon_feedback.svg';
import {ReactComponent as IconHelp} from '../../images/icon_help.svg';
import {ReactComponent as IconMyAccount} from '../../images/icon_my_account.svg';
import {ReactComponent as IconSettings} from '../../images/icon_settings.svg';

import {ReactComponent as IconLocationGray} from '../../images/icon_location_gray.svg';

import {ReactComponent as IconArrowRight} from '../../images/icon_arrow_right.svg';
import {ReactComponent as IconArrowRightGray} from '../../images/icon_arrow_right_gray.svg';


import {ReactComponent as IconCloud} from '../../images/icon_cloud.svg';
import {ReactComponent as IconAlert} from '../../images/icon_alert.svg';

import {ReactComponent as IconChevronOrange} from '../../images/icon_chevron_orange.svg';
import {ReactComponent as IconChevronRed} from '../../images/icon_chevron_red.svg';
import {ReactComponent as IconChevronWhite} from '../../images/icon_chevron_white.svg';
import {ReactComponent as IconMoreInfo} from '../../images/icon_more_info.svg';
import {ReactComponent as IconMoreInfoWhite} from '../../images/icon_more_info_white.svg';
import {ReactComponent as IconMoreInfoOrange} from '../../images/icon_more_info_orange.svg';

import {ReactComponent as IconPartyPopper} from '../../images/icon_party_popper.svg';

import {ReactComponent as IconPlayOrPark} from '../../images/icon_playorpark.svg';
import {ReactComponent as IconPlayOrParkOn} from '../../images/icon_playorpark_on.svg';

import {ReactComponent as IconMinus} from '../../images/icon_minus.svg';
import {ReactComponent as IconPlus} from '../../images/icon_plus.svg';
import {ReactComponent as IconMinusWhite} from '../../images/icon_minus_white.svg';
import {ReactComponent as IconPlusWhite} from '../../images/icon_plus_white.svg';
import {ReactComponent as IconChevronDown} from '../../images/icon_chevron_down.svg';

import {ReactComponent as IconMoreInfoLarge} from '../../images/icon_more_info_large.svg';
import {ReactComponent as IconPartyPopperLarge} from '../../images/icon_party_popper_large.svg';

import { CKSizes } from '../../lib/Styles';

export const CKIconType = {
    back: 'back',
    eye_closed: 'eye_closed',
    eye_opened: 'eye_opened',
    scanner: 'scanner',
    email: 'email',
    extra: '',
    extra_on: 'extra_on',
    home: 'home',
    home_on: 'home_on',
    loyalty_card: 'loyalty_card',
    loyalty_card_on: 'loyalty_card_on',
    more: 'more',
    more_on: 'more_on',
    stores: 'stores',
    stores_on: 'stores_on',
    cancel: 'cancel',
    cancel_grey: 'cancel_grey',
    feedback: 'feedback',
    help: 'help',
    my_account: 'my_account',
    settings: 'settings',
    arrow_right: 'arrow_right',
    arrow_right_gray: 'arrow_right_gray',
    location_gray: 'location_gray',
    cloud: 'cloud',
    alert: 'alert',
    chevron_orange: 'chevron_orange',
    chevron_red: 'chevron_red',
    chevron_white: 'chevron_white',
    chevron_down: 'chevron_down',
    more_info: 'more_info',
    more_info_white: 'more_info_white',
    more_info_orange: 'more_info_orange',
    party_popper: 'party_popper',
    playorpark: 'playorpark',
    playorpark_on: 'playorpark_on',
    minus: 'minus',
    plus: 'plus',
    minus_white: 'minus_white',
    plus_white: 'plus_white',
    more_info_large: 'more_info_large',
    party_popper_large: 'party_popper_large',
}

const CKIcon = (props) => {
    let icon = <></>

    let w = props.width ?? CKSizes.defaultIconWidth;
    let h = props.height ?? CKSizes.defaultIconHeight;

    switch(props.icon) {
        case CKIconType.back:
            icon = <IconBack style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.eye_closed:
            icon = <IconEyeClosed style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.eye_opened:
            icon = <IconEyeOpened style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.email:
            icon = <IconEmail style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.scanner:
            icon = <IconScanner style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.extra:
            icon = <IconExtra style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.extra_on:
            icon = <IconExtraOn style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.home:
            icon = <IconHome style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.home_on:
            icon = <IconHomeOn style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.loyalty_card:
            icon = <IconLoyaltyCard style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.loyalty_card_on:
            icon = <IconLoyaltyCardOn style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.more:
            icon = <IconMore style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.more_on:
            icon = <IconMoreOn style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.stores:
            icon = <IconStores style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.stores_on:
            icon = <IconStoresOn style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.cancel:
            icon = <IconCancel style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.cancel_grey:
            icon = <IconCancelGrey style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.feedback:
            icon = <IconFeedback style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.help:
            icon = <IconHelp style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.my_account:
            icon = <IconMyAccount style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.settings:
            icon = <IconSettings style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.arrow_right:
            icon = <IconArrowRight style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.arrow_right_gray:
            icon = <IconArrowRightGray style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.location_gray:
            icon = <IconLocationGray style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.cloud:
            icon = <IconCloud style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.alert:
            icon = <IconAlert style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.chevron_orange:
            icon = <IconChevronOrange style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.chevron_red:
            icon = <IconChevronRed style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.chevron_white:
            icon = <IconChevronWhite style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.chevron_down:
            icon = <IconChevronDown style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.more_info:
            icon = <IconMoreInfo style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.more_info_white:
            icon = <IconMoreInfoWhite style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.more_info_orange:
            icon = <IconMoreInfoOrange style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.party_popper:
            icon = <IconPartyPopper style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.playorpark:
            icon = <IconPlayOrPark style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.playorpark_on:
            icon = <IconPlayOrParkOn style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.minus:
            icon = <IconMinus style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.plus:
            icon = <IconPlus style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.minus_white:
            icon = <IconMinusWhite style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.plus_white:
            icon = <IconPlusWhite style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.more_info_large:
            icon = <IconMoreInfoLarge style={{width: w, height: h, ...props.style}} />
        break;
        case CKIconType.party_popper_large:
            icon = <IconPartyPopperLarge style={{width: w, height: h, ...props.style}} />
        break;
    }

    return icon;
}

export default CKIcon;