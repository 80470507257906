import { Component } from "react";
import Styles, { Colors } from '../Styles';
import CKLoading from "./CKLoading";
import View from "./View";

export default class CKButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMouseOver: false,
            isTouch: false
        }
    }
    render() {
        const isSecondary = this.props.secondary ? true : false;
        const isLink = this.props.link ? true : false;
        const isLoading = this.props.loading ? true : false;
        const noButton = this.props.no_button ? true : false;

        let props = {
            disabled: this.props.disabled,
            onClick: this.props.onPress ?? this.props.onClick,
            onMouseDown: () => {
                this.setState({
                    isTouch: true
                })
            },
            onMouseUp: () => {
                this.setState({
                    isTouch: false
                })
            },
            onTouchStart: () => {
                this.setState({
                    isTouch: true
                })
            },
            onTouchEnd: () => {
                this.setState({
                    isTouch: false
                })
            },
            onMouseOver: () => {
                this.setState({
                    isMouseOver: true
                })
            },
            onMouseOut: () => {
                this.setState({
                    isMouseOver: false,
                    isTouch: false
                })
            },
            className: this.props.custom ? 'ck-button-custom' : (isLink ? 'ck-button-link' : 'ck-button'),
            style: this.props.custom ? {
                ...(this.state.isMouseOver ? {
                    cursor: 'pointer'
                } : {}),
                ...(this.props.color ? { color: this.props.color } : {}),
                ...this.props.default_style,
                ...(this.state.isMouseOver && !this.state.isTouch ? {
                    ...this.props.hover_style
                } : {})
            } : {
                ...(this.state.isMouseOver ? {
                    cursor: 'pointer'
                } : {}),
                ...(
                    isLink ? {
                        ...Styles.actBook,
                        ...(!this.props.disabled && this.state.isMouseOver && !this.state.isTouch ? {
                            textDecoration: this.props.underline ? 'none' : 'underline'
                        } : {
                            textDecoration: this.props.underline ? 'underline' : 'none'
                        })
                    } :
                    {
                        backgroundColor: this.props.disabled ? Colors.primaryGray : (
                            this.state.isMouseOver && !this.state.isTouch ? (
                                isSecondary ? Colors.lightGray : Colors.darkRed
                            ) : (isSecondary ? Colors.white : Colors.primaryRed)
                        ),
                        borderColor: this.props.disabled ? Colors.primaryGray : (
                            this.state.isMouseOver && !this.state.isTouch ? (
                                Colors.pink
                            ) : Colors.primaryRed
                        ),
                        borderWidth: isSecondary && this.state.isMouseOver && !this.state.isTouch ? 3 : (isSecondary ? 1 : 3)
                    }
                ),
                color: this.props.disabled ? Colors.primaryDarkGray : (
                    isLink ? Colors.primaryText : (isSecondary ? Colors.primaryRed : Colors.white)
                ),
                ...this.props.style
            }
        }

        if(this.props.className) {
            props.className += ' ' + this.props.className;
        }

        let contents = <>
            {!isLoading || (isLoading && !isLink) ? this.props.children : null}
            {this.props.customChildren ? this.props.customChildren(this.state.isMouseOver && !this.state.isTouch) : null}
            {isLoading ? <div style={{
                position: 'absolute',
                right: isLink ? 0 : 6,
                top: isLink ? -18 : 4,
                bottom: 0
            }}>
                <CKLoading {...(isLink ? {red: true, width: 24, height: 24} : {})} />
            </div> : null}
        </>;

        return noButton ? <View flex={"true"} {...props}>
            {contents}
        </View> : <button {...props}>
            {contents}
        </button>
    }
}