import PublicBase from "../lib/PublicBase";
import Styles, { Colors } from "../lib/Styles";
import CommsLayer from "../lib/api/CommsLayer";
import Header from "../lib/components/Header";
import CKButton from "../lib/components/CKButton";
import ShadowPanel from "../lib/components/ShadowPanel";
import CKInput from "../lib/components/CKInput";
import View from "../lib/components/View";
import CKNavigator from "../lib/CKNavigator";
import {ReactComponent as LogoPlay} from '../images/ck_playorpark_logo.svg';
import {ReactComponent as LogoExtra} from '../images/ck_extra_logo.svg';
import Alert from "../lib/components/Alert";
import CKSeparator from "../lib/components/CKSeparator";

export default class FuelCardTransfer extends PublicBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            isValid: false,
            waitingTransfer: false,
            txtEmail: '',
            txtPassword: '',
            txtFuelCardNumber: '',
            txtEmailError: '',
            txtFuelCardNumberError: ''
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.hideLoading();
    }

    validate = (showErrors = true, field = '') => {
        
        let isValid = true;

        let newState = {
            txtEmailError: '',
            txtPasswordError: '',
            txtFuelCardNumberError: ''
        };

        if(field) {
            newState = {};
            newState[field + 'Error'] = '';
        }

        
        let emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if(this.state.txtEmail.trim() === '') {
            isValid = false;
            if(!field || field === 'txtEmail') newState.txtEmailError = 'Email address is required';
        } else if(emailRegExp.test(this.state.txtEmail) === false) {
            isValid = false;
            if(!field || field === 'txtEmail') newState.txtEmailError = 'Email address is not correct';
        }

        if(this.state.txtPassword === '') {
            isValid = false;
            if(!field || field === 'txtPassword') newState.txtPasswordError = 'Password is required';
        } else if(this.state.txtPassword.length < 8) {
            isValid = false;
            if(!field || field === 'txtPassword') newState.txtPasswordError = 'Password must be at least 8 characters.';
        } 
        

        if(this.state.txtFuelCardNumber.trim() === '') {
            isValid = false;
            if(!field || field === 'txtFuelCardNumber') newState.txtFuelCardNumberError = 'Fuel Card number is required';
        } else if(this.state.txtFuelCardNumber.length < 16 || this.state.txtFuelCardNumber.length > 18) {
            isValid = false;
            if(!field || field === 'txtFuelCardNumber') newState.txtFuelCardNumberError = 'Fuel Card number must be either 16 and 18 digits long.';
        } 

        if(showErrors) {
            this.setState(newState);
        }        

        return isValid;
    }

    transferAccount = () => {
        this.setState({
            waitingTransfer: true,
            txtEmailError: ''
        }, () => { 
            CommsLayer.fuelCardCheck(this.state.txtFuelCardNumber).then((result) => {
                if (result && result.available) {                    
                    CommsLayer.fuelCardAssign(this.state.txtFuelCardNumber, this.state.txtEmail, this.state.txtPassword).then((assign) => {
                        if (assign && assign.Success) {
                            CommsLayer.login(this.state.txtEmail, this.state.txtPassword).then((result) => {
                                CommsLayer.members().membersV1GetMemberInfo().then(async resp => {
                                    let cardsInfo = await CommsLayer.members().membersV1GetMemberSummary();
                                    
                                    if(cardsInfo) {
                                        CommsLayer.cardInfo = cardsInfo;
                                    }
                
                                    this.navigateTo(CKNavigator.fuelCardSuccess);
                                }).catch(() => {
                                    this.navigateTo(CKNavigator.fuelCard);
                                });
                            }).catch(err => {
                                this.setState({
                                    waitingTransfer: false,
                                    txtEmailError: "Sorry, we don't recognise the email address you just entered or your password was incorrect."
                                })
                            })
                        } else {
                            this.setState({
                                waitingTransfer: false,
                                txtEmailError: "Sorry, we don't recognise the email address you just entered or your password was incorrect."
                            })
                        }
                    }).catch(err => {
                        this.setState({
                            waitingTransfer: false,
                            txtEmailError: "Sorry, we don't recognise the email address you just entered or your password was incorrect."
                        })
                    })
                } else {
                    this.setState({
                        waitingTransfer: false,
                        txtFuelCardNumberError: "Sorry, there was an error transferring your account, please try again."
                    })
                }
            }).catch(err => {
                console.log(err)
                this.setState({
                    waitingTransfer: false,
                    txtFuelCardNumberError: "Sorry, we don't recognise the Fuel Card entered or was incorrect."
                })
            })
        });
    }

    render() {
        return super.render(            
            <ShadowPanel style={{
                width: '100%',
                maxWidth:'540'
            }}>
                <Header style={{
                        marginBottom: 30,
                        fontSize: 28,
                        ...Styles.actMedium,
                    }}>Fuel Card Customer Sign Up</Header>

                <p style={{
                    fontSize: 15,
                    color: '#101820',
                    ...Styles.actMedium,
                }}>
                    To transfer your Fuel Card Member benefits to your Circle K Extra account, please enter your Fuel Card number, your email and password.
                </p>

                <CKInput placeholder="Please enter Fuel Card number"
                        label="Enter Fuel Card Number"                        
                        onChange={(evt) => {
                            this.setState({
                                txtFuelCardNumber: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtFuelCardNumber')
                                });
                            });
                        }} 
                        error={this.state.txtFuelCardNumberError}
                        value={this.state.txtFuelCardNumber} 
                    />

                <View style={{
                    marginTop: 20,
                    padding: 10,
                    borderRadius: 8,
                    backgroundColor: '#f7f5f2',
                    textAlign: 'center',
                    marginBottom: 30,
                    paddingBottom: 1
                }}>
                    <LogoPlay style={{
                        float: 'left',
                        width: 128,
                        marginLeft: 80,
                        marginTop: 18
                    }}/>
                    <LogoExtra style={{
                        float: 'left',
                        width: 128,
                        marginLeft: 40
                    }} />
                    <div 
                        style={{
                            clear: 'both'
                    }}></div>
                    <View style={{
                        textAlign: 'left',
                        paddingLeft:20,
                        paddingRight:20
                    }}>
                    <CKInput error={this.state.txtEmailError} placeholder="Please enter email address" label="Email address" onChange={(evt) => {
                        this.setState({
                            txtEmail: evt.target.value,
                            txtEmailError: ''
                        },() => {
                            this.setState({
                                isValid: this.validate(true, 'txtEmail')
                            });
                        });
                    }} value={this.state.txtEmail} type="email" />

                    <div style={{marginTop: 24}}></div>

                    <View style={{
                        marginBottom: 20
                    }}>
                        <CKInput error={this.state.txtPasswordError}  placeholder="Please enter password" label="Password" onChange={(evt) => {
                            this.setState({
                                txtPassword: evt.target.value,
                                txtPasswordError: ''
                            },() => {
                                this.setState({
                                    isValid: this.validate(true, 'txtPassword')
                                });
                            });
                        }} value={this.state.txtPassword} type="password" />
                    </View>         

                    <div style={{
                        textAlign: 'right'
                    }}>
                        <CKButton onClick={() => {
                            this.navigateTo(CKNavigator.fuelCardSignupForgot);
                        }} link style={{
                            marginBottom: 20
                        }}>
                            Forgot password?
                        </CKButton>
                    </div>     
                    </View>  
                </View>

                <div style={{
                    textAlign: 'center'
                }}>
                    <CKButton style={{
                        maxWidth: 320
                    }} loading={this.state.waitingTransfer} onClick={this.transferAccount} disabled={!this.state.isValid} >Transfer Account</CKButton>
                </div>

                <p style={{
                    textAlign: 'center',
                    ...Styles.actBook,
                    fontSize: 12,
                    marginTop: 25
                }}>By signing up you agree to the <CKButton style={{
                    color: Colors.orange,
                    ...Styles.actBook,
                    fontSize: 12
                }} underline link onPress={() => {
                    Alert.showTerms();
                }}>Terms of Use</CKButton></p>
                <View style={{ textAlign: 'center'}}>
                <CKButton style={{
                        marginTop: 12,
                        marginBottom: 30,
                        maxWidth: 320,
                        backgroundColor: 'transparent'
                    }} secondary onClick={() => {
                    this.navigateTo(CKNavigator.fuelCard);
                }}>Cancel</CKButton>
                </View>
            </ShadowPanel>
        )
    }
}