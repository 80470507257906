import PublicBase from "../lib/PublicBase";
import Styles from "../lib/Styles";
import CommsLayer from "../lib/api/CommsLayer";
import Header from "../lib/components/Header";
import CKButton from "../lib/components/CKButton";
import CKInput from "../lib/components/CKInput";
import CKSeparator from "../lib/components/CKSeparator";
import ShadowPanel from "../lib/components/ShadowPanel";
import CKNavigator from "../lib/CKNavigator";
import View from "../lib/components/View";
import {ReactComponent as LogoPro} from '../images/ck_pro_logo.svg';

export default class Home extends PublicBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            isLoginInProgress: false,
            txtEmail: '',
            txtPassword: ''
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.hideLoading();
    }

    performLogin = () => {
        this.setState({
            isLoginInProgress: true,
            txtEmailError: ''
        }, () => {
            CommsLayer.login(this.state.txtEmail, this.state.txtPassword).then((result) => {
                CommsLayer.members().membersV1GetMemberInfo().then(async resp => {
                    let cardsInfo = await CommsLayer.members().membersV1GetMemberSummary();
                    
                    if(cardsInfo) {
                        CommsLayer.cardInfo = cardsInfo;
                    }

                    this.navigateTo(CKNavigator.home);
                }).catch(() => {
                    this.navigateTo(CKNavigator.home);
                });
            }).catch(err => {
                this.setState({
                    isLoginInProgress: false,
                    txtEmailError: "Sorry, we don't recognise the email address you just entered or your password was incorrect."
                })
            })
        })
    }

    render() {
        return super.render(            
            <ShadowPanel style={{
                width: '100%'
            }}>
                <View style={{
                    padding: 20
                }}>
                    <Header style={{
                        marginBottom: 30
                    }}>Log In</Header>

                    <CKInput onEnter={() => {
                        if(!this.state.isLoginInProgress) this.performLogin();
                    }} error={this.state.txtEmailError} placeholder="Please enter email address" label="Email address" onChange={(evt) => {
                        this.setState({
                            txtEmail: evt.target.value,
                            txtEmailError: ''
                        })
                    }} value={this.state.txtEmail} type="email" />

                    <div style={{marginTop: 24}}></div>

                    <View style={{
                        marginBottom: 40
                    }}>
                        <CKInput onEnter={() => {
                            if(!this.state.isLoginInProgress) this.performLogin();
                        }} placeholder="Please enter password" label="Password" onChange={(evt) => {
                            this.setState({
                                txtPassword: evt.target.value
                            })
                        }} value={this.state.txtPassword} type="password" />
                    </View>

                    <CKButton loading={this.state.isLoginInProgress} onClick={this.performLogin}>Login</CKButton>

                    <div style={{
                        textAlign: 'right'
                    }}>
                        <CKButton onClick={() => {
                            this.navigateTo(CKNavigator.forgot);
                        }} link style={{
                            marginTop: 20
                        }}>
                            Forgot password?
                        </CKButton>
                    </div>

                    <CKSeparator />

                    <p style={{
                        fontSize: 16,
                        ...Styles.actMedium,
                        padding: 0,
                        margin: 0,
                        textAlign: 'center'
                    }}>
                        New to Circle K?
                    </p>

                    <CKButton style={{
                        marginTop: 15
                    }} secondary onClick={() => {
                        this.navigateTo(CKNavigator.signup);
                    }}>Sign Up</CKButton>
                </View>
                <View style={{
                    marginTop: 20,
                    padding: 20,
                    borderRadius: 15,
                    backgroundColor: '#f7f5f2'
                }}>
                    <div className="logo-pro-container">
                        <LogoPro />
                    </div>
                    <p style={{
                        fontSize: 16,
                        ...Styles.actMedium,
                        padding: 0,
                        margin: 0,
                        textAlign: 'left'
                    }}>
                        You have a Fuel Card account.
                    </p>
                    <p style={{
                        fontSize: 15,
                        ...Styles.actMedium,
                        padding: 0,
                        marginTop: 10,
                        textAlign: 'left'
                    }}>
                        Get additional benefits on Circle K Extra.
                    </p>
                    <CKButton style={{
                        marginTop: 5,
                        backgroundColor: 'transparent'
                    }} secondary onClick={() => {
                        this.navigateTo(CKNavigator.fuelCard);
                    }}>Sign Up</CKButton>
                </View>
            </ShadowPanel>
        )
    }
}