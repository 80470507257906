import { Component } from "react";
import Styles, { CKColors, CKPadding, CKSizes, Colors } from "../Styles";
import View from "./View";
import CKText from "./CKText";
import Dropdown from 'react-bootstrap/Dropdown';
import CKIcon, { CKIconType } from "./CKIcon";
import moment from "moment";
import CKButton from "./CKButton";

export default class CKInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
            showDropdown : false,

            dateDay: this.props.date ? this.extractPart(this.props.value, 'DD') : '',
            dateMonth: this.props.date ? this.extractPart(this.props.value, 'MM') : '',
            dateYear: this.props.date ? this.extractPart(this.props.value, 'YYYY') : '',

            isPasswordVisible: false
        }
    }

    extractPart = (d, format = '') => {
        if(!d) return '';
    
        let _d = moment(d, 'YYYY-MM-DD');
        if(!_d) return '';
    
        return _d.format(format);
    }

    generateDays = () => {
        let d = [];
        for(var i = 1; i <= 31; i++) {
            d.push(i < 10 ? '0' + i : i);
        }
        return d;
    }
    generateMonths = () => {
        let d = [];
        for(var i = 1; i <= 12; i++) {
            d.push(i < 10 ? '0' + i : i);
        }
        return d;
    }
    generateYears = () => {
        let d = [];
        for(var i = new Date().getFullYear() - 113; i <= new Date().getFullYear() - 16; i++) {
            d.push(i);
        }
        return d.reverse();
    }

    render() {
        const isDropdown = this.props.dropdownData;
        const isDate = this.props.date;
        const isPassword = this.props.type ==='password';
        const className = 'ipnut-field ' + (this.props.className ?? '');

        const notAllowedProps = ['onEnter'];
        let newProps = {};
        Object.keys(this.props).forEach(p => {
            if(notAllowedProps.indexOf(p) >= 0) {
                return;
            }

            newProps[p] = this.props[p];
        })

        var style = {
            borderColor: this.state.isFocused ? Colors.orange : Colors.borderColor,
            ...this.props.style
        }

        return <View style={{
            position: 'relative',
            display: 'flex',
            flex: 1,
            flexDirection: 'column'
        }}>
            {this.props.label ? <span style={{
                marginBottom: 6,
                display: 'inline-block',
                fontSize: 12,
                color: Colors.primaryText,
                ...Styles.actMedium
            }}>{this.props.label}</span> : null}

            {this.props.readonly ? <View style={{
                    borderBottom: "1px solid " + CKColors.borderColor,
                    ...this.props.style
                }}>
                    <CKText style={{
                        ...Styles.actBook,
                        fontSize: 16,
                        lineHeight: "16px",
                        color: CKColors.readOnlyColor,
                        marginTop: 7,
                        marginBottom: 7,
                        display: 'flex',
                        ...this.props.field_style
                    }}>{this.props.on_empty && !this.props.value ? this.props.on_empty : this.props.value}</CKText>
                </View>
                :
                (isDropdown ? 
                    <Dropdown autoClose={true} onToggle={(nextShow) => {
                        this.setState({
                            showDropdown: nextShow
                        })
                    }} onSelect={(eventKey, event) => {
                        var valIndex = event.target.getAttribute('value');
                        this.props.onChange({
                            target: {
                                value: this.props.dropdownData[valIndex]
                            }
                        })
                    }} show={this.state.showDropdown} style={{
                        position: 'relative',
                        display: 'flex',
                        flex: 1
                    }}>
                        <Dropdown.Toggle className={className} variant='Primary' style={{
                            ...style,
                            textAlign: 'left',
                            position: 'relative',
                            cursor: 'pointer'
                        }}>
                            <CKText style={{
                                color: !this.props.value ? CKColors.readOnlyColor : CKColors.primaryText,
                                ...this.props.field_style
                            }}>{!this.props.value ? this.props.placeholder : this.props.value}</CKText>

                            <View style={{
                                position: 'absolute',
                                right: CKPadding.default / 2,
                                top: 0,
                                bottom: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <CKIcon style={{
                                    transform: "rotate(90deg)"
                                }} icon={CKIconType.arrow_right} width={16} height={16} />
                            </View>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{
                            width: '100%',
                            flexDirection: 'column',
                            backgroundColor: Colors.white,
                            maxHeight: "182px",
                            overflow: 'scroll'
                        }}>
                            {this.props.dropdownData.map((d, index) => {
                                return <Dropdown.Item as={"div"} style={{
                                    height: 46,
                                    border: "1px solid " + CKColors.borderColor,
                                    borderBottom: !(index ===this.props.dropdownData.length - 1) ? "none" : "1px solid " + CKColors.borderColor,
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    textAlign: 'left',
                                    paddingLeft: CKPadding.default / 2,
                                    backgroundColor: this.props.value ===(this.props.getValue ? this.props.getValue(d) : d) ? Colors.primaryRed : Colors.white,
                                    textDecoration: 'none',
                                    ...Styles.actBook,
                                    cursor: 'pointer',
                                    color: this.props.value ===(this.props.getValue ? this.props.getValue(d) : d) ? Colors.white : Colors.primaryText,
                                }} key={'d_' + index} value={index}>{this.props.rowTextForSelection ? this.props.rowTextForSelection(d) : d}</Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                :
                    (isDate ?
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            ...this.props.style
                        }}>
                            <View style={{
                                display: "flex",
                                flex: 1,
                                marginRight: CKPadding.default
                            }}>
                                <CKInput value={this.state.dateDay} dropdownData={this.generateDays()} placeholder={'Day'} style={{
                                    flex: 1
                                }} onChange={(event) => {
                                    this.setState({
                                        dateDay: event.target.value
                                    });

                                    if(this.state.dateMonth && this.state.dateYear && this.props.onChange) {
                                        this.props.onChange({
                                            target: {
                                                value: this.state.dateYear + '-' + this.state.dateMonth + '-' + event.target.value
                                            }
                                        })
                                    }
                                }} />
                            </View>

                            <View style={{
                                display: "flex",
                                flex: 1,
                                marginRight: CKPadding.default
                            }}>
                                <CKInput value={this.state.dateMonth} dropdownData={this.generateMonths()} placeholder={'Month'} style={{
                                    flex: 1
                                }} onChange={(event) => {
                                    this.setState({
                                        dateMonth: event.target.value
                                    });
                                    if(this.state.dateDay && this.state.dateYear && this.props.onChange) {
                                        this.props.onChange({
                                            target: {
                                                value: this.state.dateYear + '-' + event.target.value + '-' + this.state.dateDay
                                            }
                                        })
                                    }
                                }} />
                            </View>

                            <CKInput value={this.state.dateYear} dropdownData={this.generateYears()} placeholder={'Year'} style={{
                                flex: 1
                            }} onChange={(event) => {
                                this.setState({
                                    dateYear: event.target.value
                                });
            
                                if(this.state.dateDay && this.state.dateMonth && this.props.onChange) {
                                    this.props.onChange({
                                        target: {
                                            value: event.target.value + '-' + this.state.dateMonth + '-' + this.state.dateDay
                                        }
                                    })
                                }
                            }} />
                        </View>
                        :
                        <View style={{
                            position: 'relative'
                        }}>
                            <input
                                {...(newProps)}
                                {...(isPassword && this.state.isPasswordVisible ? {type: ''} : {})}
                                onKeyDown={(e) => {
                                    if(e.key === 'Enter') {
                                        if(this.props.onEnter) this.props.onEnter();
                                    }
                                }}
                                onFocus={() => {
                                    this.setState({
                                        isFocused: true
                                    })
                                }}
                                onBlur={() => {
                                    this.setState({
                                        isFocused: false
                                    })
                                }}
                                className={className}
                                style={style}
                            />
                            {isPassword ? <CKButton default_style={{
                                position: 'absolute',
                                right: CKPadding.default / 2,
                                bottom: 0,
                                height: CKSizes.inputHeight,
                                justifyContent: 'center'
                            }} onPress={() => {
                                this.setState({
                                    isPasswordVisible: !this.state.isPasswordVisible
                                });
                            }} custom >
                                <CKIcon width={CKSizes.smallIconWidth} height={CKSizes.smallIconHeight} icon={this.state.isPasswordVisible ? CKIconType.eye_opened : CKIconType.eye_closed} />
                            </CKButton> : <></> }
                        </View>
                    )
                )
            }

            {this.props.sublabel || this.props.error ? <p style={{
                ...Styles.actBook,
                fontSize: 12,
                color: this.props.error ? Colors.primaryRed : Colors.placeholderColor,
                marginTop: 8,
                paddingBottom: 0,
                paddingTop: 0,
                marginBottom: 0
            }}>{this.props.error ? this.props.error : this.props.sublabel}</p> : null}
        </View>
    }
}