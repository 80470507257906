import PublicBase from "../lib/PublicBase";
import Styles, { Colors } from "../lib/Styles";
import CKButton from "../lib/components/CKButton";
import CKInput from "../lib/components/CKInput";
import Header from "../lib/components/Header";
import ShadowPanel from "../lib/components/ShadowPanel";
import CKNavigator from "../lib/CKNavigator";
import CKSeparator from "../lib/components/CKSeparator";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";
import Alert from "../lib/components/Alert";
import View from "../lib/components/View";

export default class Signup extends PublicBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            waitingSignup: false,
            isValid: false,            
            type: props.type,

            txtFirstName: '',
            txtFirstNameError: '',

            txtSurname: '',
            txtSurnameError: '',

            txtEmail: '',
            txtEmailError: '',

            txtPassword: '',
            txtPasswordError: '',

            txtConfirmPassword: '',
            txtConfirmPasswordError: '',

            txtFuelCardNumber: '',
            txtFuelCardNumberError: '',

            txtDob: '',
            txtDobError: ''
        }
    }



    signup = async () => {
        if(this.validate()) {
            this.setState({
                waitingSignup: true
            });
            // Check Fuel Card
            let fuelCard = null;
            if ( this.state.type === "fuelcard" ) {
                try {
                    let result = await CommsLayer.fuelCardCheck(this.state.txtFuelCardNumber);
                    if (result && result.available) {
                        fuelCard = this.state.txtFuelCardNumber;
                    }
                } catch (err) {
                    this.setState({
                        waitingSignup: false,
                        txtFuelCardNumberError: "Sorry, we don't recognise the Fuel Card entered or was incorrect."
                    })
                }
            }

            if (this.state.type !== "fuelcard" || ( this.state.type === "fuelcard" && fuelCard != null)) {
                // lets perform registration and take user to the next step
                CommsLayer.members().membersV1RegisterMember(
                    this.state.txtFirstName,
                    this.state.txtSurname,
                    this.state.txtEmail,
                    this.state.txtPassword,
                    this.state.txtDob
                ).then(() => {
                    // lets try to log user in
                    CommsLayer.login(this.state.txtEmail, this.state.txtPassword).then(() => {
                        CommsLayer.members().membersV1GetMemberInfo().then(async resp => {
                            let cardsInfo = await CommsLayer.members().membersV1GetMemberSummary();
                            if (resp) {
                                resp.DateOfBirth = this.state.txtDob
                                await CommsLayer.members().membersV1UpdateMemberPatch(resp);
                            }
                            if(cardsInfo) {
                                CommsLayer.cardInfo = cardsInfo;
                            }

                            // assign group
                            if (this.state.type === "fuelcard") {
                                await CommsLayer.fuelCardAssign(this.state.txtFuelCardNumber, this.state.txtEmail, this.state.txtPassword);
                            }

                            this.navigateTo(CKNavigator.home);
                        }).catch(() => {
                            this.navigateTo(CKNavigator.home);
                        });
                    }).catch((err) => {
                        alert(err && err.message ? err.message : ErrorCodes.generalAPIError());
                    }).finally(() => {
                        this.setState({
                            waitingSignup: false
                        })
                    });
                }).catch(err => {
                    if(err && err.code === 'WS42') {
                        this.setState({
                            txtEmailError: 'Email already in use',
                            isValid: false
                        })
                    } else {
                        alert(err && err.message ? err.message : ErrorCodes.generalAPIError());
                    }

                    this.setState({
                        waitingSignup: false
                    })
                });
            }
        }
    }

    validate = (showErrors = true, field = '') => {
        let isValid = true;

        let newState = {
            txtFirstNameError: '',
            txtSurnameError: '',
            txtEmailError: '',
            txtPasswordError: '',
            txtConfirmPasswordError: ''
        };

        if(field) {
            newState = {};
            newState[field + 'Error'] = '';
        }

        if ( this.state.type === "fuelcard" ) {
            if(this.state.txtFuelCardNumber.trim() === '') {
                isValid = false;
                if(!field || field === 'txtFuelCardNumber') newState.txtFuelCardNumberError = 'Fuel Card number is required';
            } else if(this.state.txtFuelCardNumber.length < 16 || this.state.txtFuelCardNumber.length > 18) {
                isValid = false;
                if(!field || field === 'txtFuelCardNumber') newState.txtFuelCardNumberError = 'Fuel Card number must be either 16 and 18 digits long.';
            } 
        }

        if(this.state.txtFirstName.trim() === '') {
            isValid = false;
            if(!field || field === 'txtFirstName') newState.txtFirstNameError = 'First name is required';
        }

        if(this.state.txtSurname.trim() === '') {
            isValid = false;
            if(!field || field === 'txtSurname') newState.txtSurnameError = 'Surname is required';
        }

        let emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if(this.state.txtEmail.trim() === '') {
            isValid = false;
            if(!field || field === 'txtEmail') newState.txtEmailError = 'Email address is required';
        } else if(emailRegExp.test(this.state.txtEmail) === false) {
            isValid = false;
            if(!field || field === 'txtEmail') newState.txtEmailError = 'Email address is not correct';
        }

        if(this.state.txtPassword === '') {
            isValid = false;
            if(!field || field === 'txtPassword') newState.txtPasswordError = 'Password is required';
        } else if(this.state.txtPassword.length < 8) {
            isValid = false;
            if(!field || field === 'txtPassword') newState.txtPasswordError = 'Password must be at least 8 characters.';
        } else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$@!%&*?.*\W_]{8,}$/.test(this.state.txtPassword)) {
            isValid = false;
            if(!field || field === 'txtPassword') newState.txtPasswordError = 'Your password must consist of at least one UPPERCASE letter, lowercase letter, 1 number, and must be eight characters or more in length.';
        }

        if(this.state.txtConfirmPassword === '') {
            isValid = false;
            if(!field || field === 'txtConfirmPassword') newState.txtConfirmPasswordError = 'Please confirm your password';
        } else if(this.state.txtPassword !== this.state.txtConfirmPassword) {
            isValid = false;
            if(!field || field === 'txtConfirmPassword') newState.txtConfirmPasswordError = 'Both passwords must match.';
        }

        if(this.state.txtDob.trim() == '') {
            isValid = false;
            if(!field || field == 'txtDob') newState.txtDobError = 'DOB is required';
        }
        else if (!((new Date()).getTime() >= new Date(new Date(this.state.txtDob).getFullYear() + 16, new Date(this.state.txtDob).getMonth(), new Date(this.state.txtDob).getDate()).getTime())) {
            isValid = false;
            if(field == 'txtDob') newState.txtDobError = 'You must be 16 years of age or older';
        }

        if(showErrors) {
            this.setState(newState);
        }

        return isValid;
    }

    render() {
        return super.render(
            
            <ShadowPanel style={{
                maxWidth: 540,
                width: '100%'
            }}>
                {this.state.type === "normal" && <Header>Sign Up</Header>}
                {this.state.type === "fuelcard" && <Header>Fuel Card Customer Sign Up</Header>}
                {this.state.type === "fuelcard" &&
                <div style={{
                    marginBottom: 20,
                    marginTop: 30,                    
                    borderRadius: 8,
                    backgroundColor: '#f7f5f2'
                }}>
                    <div style={{
                        paddingBottom: 20,
                        marginLeft: 10,
                        marginRight: 10,
                        paddingTop: 20
                    }}>
                    <CKInput placeholder="Please enter Fuel Card number"
                        label="Enter Fuel Card Number"                        
                        onChange={(evt) => {
                            this.setState({
                                txtFuelCardNumber: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtFuelCardNumber')
                                });
                            });
                        }} 
                        error={this.state.txtFuelCardNumberError}
                        value={this.state.txtFuelCardNumber} 
                    />
                    </div>
                </div>
                }
                <View style={{
                    marginLeft:10,
                    marginRight:10
                }}>
                <div style={{
                    marginBottom: 20,
                    marginTop: 30
                }}>
                    <CKInput placeholder="Please enter first name"
                        label="First Name"
                        onChange={(evt) => {
                            this.setState({
                                txtFirstName: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtFirstName')
                                });
                            });
                        }} 
                        error={this.state.txtFirstNameError}
                        value={this.state.txtFirstName} 
                    />
                </div>

                <div style={{
                    marginBottom: 20
                }}>
                    <CKInput placeholder="Please enter surname"
                        label="Surname"
                        onChange={(evt) => {
                            this.setState({
                                txtSurname: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtSurname')
                                });
                            });
                        }}
                        error={this.state.txtSurnameError}
                        value={this.state.txtSurname}
                    />
                </div>
                <div style={{
                    marginBottom: 20
                }}>
                    <CKInput autoCorrect={false} date 
                        label='Date of Birth'
                        onChange={(evt) => {
                            this.setState({
                                txtDob: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtDob')
                                });
                            });
                        }}
                        error={this.state.txtDobError}
                        value={this.state.txtDob}
                    />
                </div>
                <div style={{
                    marginBottom: 20
                }}>
                    <CKInput placeholder="Please enter email address"
                        label="Email address"
                        onChange={(evt) => {
                            this.setState({
                                txtEmail: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtEmail')
                                });
                            });
                        }} 
                        error={this.state.txtEmailError}
                        value={this.state.txtEmail} 
                    />
                </div>

                <div style={{
                    marginBottom: 20
                }}>
                    <CKInput placeholder="Please enter password"
                        label="Password"
                        onChange={(evt) => {
                            this.setState({
                                txtPassword: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtPassword')
                                });
                            });
                        }} 
                        type="password"
                        error={this.state.txtPasswordError}
                        value={this.state.txtPassword} 
                        sublabel='Your password must consist of at least one UPPERCASE letter, lowercase letter, 1 number, and must be eight characters or more in length.'
                    />
                </div>

                <div style={{
                    marginBottom: 30
                }}>
                    <CKInput placeholder="Please confirm password"
                        label="Confirm Password"
                        onChange={(evt) => {
                            this.setState({
                                txtConfirmPassword: evt.target.value
                            }, () => {
                                this.setState({
                                    isValid: this.validate(true, 'txtConfirmPassword')
                                });
                            });
                        }}
                        type="password"
                        error={this.state.txtConfirmPasswordError}
                        value={this.state.txtConfirmPassword}
                        sublabel='Both passwords must match.'
                    />
                </div>

                
                <div style={{
                    textAlign: 'center'
                }}>
                    <CKButton style={{
                        maxWidth: 320
                    }} loading={this.state.waitingSignup} disabled={!this.state.isValid} onClick={this.signup}>Sign Up</CKButton>
                </div>

                <p style={{
                    textAlign: 'center',
                    ...Styles.actBook,
                    fontSize: 12,
                    marginTop: 25
                }}>By signing up you agree to the <CKButton style={{
                    color: Colors.orange,
                    ...Styles.actBook,
                    fontSize: 12
                }} underline link onPress={() => {
                    Alert.showTerms();
                }}>Terms of Use</CKButton></p>

                {this.state.type === "normal" && <View>
                <CKSeparator smaller />

                <p style={{
                    fontSize: 16,
                    ...Styles.actMedium,
                    padding: 0,
                    margin: 0,
                    textAlign: 'center'
                }}>
                    Already have an account?
                </p>


                <div style={{
                    textAlign: 'center',
                    marginTop: 15
                }}>
                    <CKButton style={{
                        maxWidth: 320
                    }} secondary onClick={() => {
                        this.navigateTo(CKNavigator.login);
                    }}>Log In</CKButton>
                </div>
                </View>}

                {this.state.type === "fuelcard" && <View style={{ textAlign: 'center'}}>
                <CKButton style={{
                        marginTop: 12,
                        marginBottom: 30,
                        maxWidth: 320,
                        backgroundColor: 'transparent'
                    }} secondary onClick={() => {
                    this.navigateTo(CKNavigator.fuelCard);
                }}>Cancel</CKButton>
                </View>}
            </View>
            </ShadowPanel>
        )
    }
}