import { CKColors, CKPadding, CKSizes } from "../lib/Styles";
import LoggedInBase from "./LoggedInBase";
import Styles from '../lib/Styles';
import moment from 'moment';

import {ReactComponent as InforCardStore} from '../images/icon_store.svg';
import {ReactComponent as IconArrowViewMore} from '../images/icon_chevron_red.svg';
import IconArrowBase from '../images/extra_screen_arrow_deco.png';
import CKButton from "../lib/components/CKButton";
import { CKStrings } from "../lib/components/CKStrings";
import CKOffer from "../lib/components/CKOffer";
import CommsLayer from "../lib/api/CommsLayer";
import CKLoading from "../lib/components/CKLoading";
import CKIcon, { CKIconType } from "../lib/components/CKIcon";
import View from "../lib/components/View";
import CKText from "../lib/components/CKText";
import Alert from "../lib/components/Alert";

import CKSpacer from '../lib/components/CKSpacer';

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import CKNavigator, { CKNavigatorStorage } from "../lib/CKNavigator";

export default class Home extends LoggedInBase {
    screenName = 'home'

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            
            loadingCardNumber: true,
            memberStatusLoading: true,
            showMemberModal: false,
            pending_text: '',
            pending_title: '',
            cardNum: '',
            firstName: '',
            memberStatus: '',
            offers: {
                fuel: [],
                surprise: [],
                counting: [],
                view_more: [],
                other: [],
                total_offers: 0
            },
            infoCard: false,
            levels: [],
            month: moment().add(1, "M").format('MMMM'),
            nextMonth: moment().add(1, 'M').format('MMMM'),
            daysRemaining: (moment().endOf('month')).diff(moment(), 'days'),
            LoyaltyBalance: 0,
            maxLoyaltyBalance: 0,
            membership_info: '',
            showMemberInfoModal: false,
            showInfoCardModal: false,
            how_extra_works: false,
            fixedLevelTextToggle: false,
            fixedLevelTextMessage: false
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    loadData = async () => {
        try {
            await CommsLayer.xmls().howExtraWorks().then(result => {
                this.setState({
                    how_extra_works: result
                });
            })
        } catch {}

        try {
            await CommsLayer.xmls().infoCard().then(result => {
                this.setState({
                    infoCard: result
                });                
            })
        } catch {}

        try {
            await CommsLayer.xmls().levels().then(levels => {
                let maxLoyaltyBalance = 0;
                levels.tiered.forEach(t => {
                    if(t.max && maxLoyaltyBalance < t.max) maxLoyaltyBalance = t.max;
                })

                this.setState({
                    levels: levels.tiered,
                    maxLoyaltyBalance,
                    fixedLevelTextToggle: levels.fixed_level_text_toggle,
                    fixedLevelTextMessage: levels.fixed_level_text_message
                });
            })

            // lets load QR code
            await CommsLayer.members().membersV1GetMemberSummary().then(cardInfo => {
                if(cardInfo) {
                    this.setState({
                        LoyaltyBalance: Number(cardInfo.LoyaltyBalance).toFixed(2)
                    })
                }

                if(cardInfo && cardInfo.CardNum) {
                    this.setState({
                        loadingCardNumber: false,
                        cardNum: cardInfo.CardNum
                    });
                }
    
                if(cardInfo && cardInfo.FirstName) {
                    this.setState({
                        loadingCardNumber: false,
                        firstName: cardInfo.FirstName
                    });
                }
            });
    
            await CommsLayer.clubs().get().then(async result => {                         
                if(result.my_club) {
                    this.setState({
                        memberStatus: result.my_club.text
                    })
                } else {
                    this.setState({
                        memberStatus: ''
                    })

                    // lets try to see if there is default club to assign member to                    
                    if(result.default_club) {
                        try {
                            let addClubResult = await CommsLayer.clubs().clubsV1AddMemberToClub(result.default_club.group_id);
                            
                            if(addClubResult && addClubResult.Success) {
                                this.setState({
                                    memberStatus: result.default_club.text
                                })
                            }
                        } catch { /* Nothing to Do, failed to add user to the club */}
                    }
                }
    
                if(result.pending_text || result.pending_title) {
                    this.setState({
                        pending_text: result.pending_text,
                        pending_title: result.pending_title
                    })
                }

                if(result.membership_info) {
                    this.setState({
                        membership_info: result.membership_info
                    })
                }

                return result;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.setState({
                    memberStatusLoading: false
                })
            });

            let clubsResult = await CommsLayer.clubs().get().then(async result => {                
                if(result.my_club) {
                    this.setState({
                        memberStatus: result.my_club.text,
                        isSpecialGroup: result.isSpecial,
                        myClub: result.my_club
                    })
                } else {
                    this.setState({
                        memberStatus: ''
                    })

                    // lets try to see if there is default club to assign member to
                    if(result.default_club) {
                        try {
                            let addClubResult = await CommsLayer.clubs().clubsV1AddMemberToClub(result.default_club.group_id);
                            
                            if(addClubResult && addClubResult.Success) {
                                this.setState({
                                    memberStatus: result.default_club.text,
                                    myClub: result.default_club
                                })
                            }
                        } catch { /* Nothing to Do, failed to add user to the club */}
                    }
                }
    
                if(result.pending_text || result.pending_title) {
                    this.setState({
                        pending_text: result.pending_text,
                        pending_title: result.pending_title
                    })
                }

                if(result.membership_info) {
                    this.setState({
                        membership_info: result.membership_info
                    })
                }

                return result;
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.setState({
                    memberStatusLoading: false
                })
            });


            // lets retrieve all offers for the customer
            await CommsLayer.offers().offersV1GetCurrentOffersWithDetails().then(offers => {
                this.setState({
                    offers
                });
            });
        } catch(err) {
            console.log(err);
        }

        this.setState({isRefreshing: false}, this.hideScreenLoading)
    }

    render() {
        let completedLevel = false;
        let myLevel = false;
        let isLastLevel = false;

        for (var i = 0; i < this.state.levels.length; i++) {
            let level = this.state.levels[i];
            if (level.text.toLowerCase() == this.state.memberStatus.toLowerCase()) {
                myLevel = level;
            }

            if (this.state.LoyaltyBalance >= level.max) {
                completedLevel = level;
                if (i === this.state.levels.length - 1) {
                isLastLevel = true;
                }
            }
        }

        let progressPercentage = this.state.maxLoyaltyBalance
        ? (myLevel.max * 100) /
            (this.state.maxLoyaltyBalance + this.state.maxLoyaltyBalance / 2) + (100.0 / this.state.levels.length)
        : 100;

        if (progressPercentage > 100) progressPercentage = 100;
        if (progressPercentage < 0) progressPercentage = 0;

        let totalBarWidth = 300 - CKPadding.default * 4;

        let message = null;

        if(isLastLevel) {
            // achieved
            message = <View style={{
                flexDirection: 'row',
                 display: 'inline-flex',
                 padding: 8,
            }}>
            <View style={{
              width: 36, height: 36, 
              borderRadius: 18, 
              borderWidth: 1, 
              borderColor: CKColors.white, 
              backgroundColor: CKColors.white,
              marginLeft: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center'
            }}>
                <CKIcon width={24} height={24} icon={CKIconType.party_popper_large} />
            </View>
            <View style={{flex: 1, textAlign: 'left', marginLeft: 10}}>
                <CKText className="progress-next-level-text-win">
                    Congratulations you'll {myLevel.max === completedLevel.max ? 'stay' : 'upgrade'}{' '}
                </CKText>
                <CKText className="progress-next-level-text-win">
                    {myLevel.max === completedLevel.max ? 'on' : 'to'} {(this.state.levels.length > 0 ? (this.state.levels[this.state.levels.length - 1].text) : '')} in {this.state.month}!
                </CKText>
            </View>
               
            </View>;
        } else {
            // calculate next level that is required from our amount
            let nextLevel = false;
            let prevLevel = false;

            for (var i = 0; i < this.state.levels.length; i++) {
                let level = this.state.levels[i];
                if (!nextLevel) nextLevel = level;

                if (this.state.LoyaltyBalance < level.max) {
                    nextLevel = level;
                    break;
                }
            }
            for(var i = this.state.levels.length - 1; i >=0; i--) {
                let level = this.state.levels[i];

                if(this.state.LoyaltyBalance >= level.max) {
                    prevLevel = level;
                    break;
                }
            }

            // lets check if downgrade or upgrade
            let isDowngrade = myLevel.max > completedLevel.max;

            if (this.state.fixedLevelTextToggle) {
                message = <CKText style={{
                    fontSize: CKSizes.font.px10,
                    ...Styles.actMedium,
                    color: CKColors.offBlack,
                    textAlign: 'center'
                }}>
                    {this.state.fixedLevelTextMessage}
                </CKText>
            } else {
                if(nextLevel) {
                    if(isDowngrade) {
                        message = <CKText className="progress-next-level-text">
                            You will move to{' '}
                            <CKText className="special">
                                {prevLevel.text}
                            </CKText>{' '}
                            in {this.state.nextMonth}

                            <div className="or-divider">
                                <div className="or-container">
                                    <div className="or-line" style={{float: 'right', marginRight: 10}}/>
                                </div>
                                <div>
                                    <CKText className="progress-next-level-text">
                                        or
                                    </CKText>
                                </div>
                                <div className="or-container">
                                    <div className="or-line" style={{float: 'left', marginLeft: 10}}/>
                                </div>
                            </div>

                            <CKText style={{display: 'block', paddingHorizontal: '20px', wordWrap: 'break-word'}}>
                                <CKText style={{display: 'inline'}}>
                                    Spend <CKText className="special" style={{display: 'inline'}}>
                                    {CKStrings.convertToUserCurrency('€')}{Number(myLevel.max - this.state.LoyaltyBalance).toFixed(2)}
                                    </CKText> {this.state.daysRemaining > 0 ? 'in ' : 'today'}{this.state.daysRemaining > 0 ? this.state.daysRemaining : ''}{this.state.daysRemaining > 0 ? ' day' : ''}{this.state.daysRemaining > 1 ? 's' : ''}{' '}
                                </CKText>
                                <CKText style={{display: 'block'}}>
                                    to stay on {myLevel.text} next month
                                </CKText>
                            </CKText>
                            
                        </CKText>
                    } else {
                        message = <CKText className="progress-next-level-text">
                        {this.state.memberStatus === completedLevel.text ? (
                            <CKText className="progress-next-level-text">
                            You will stay in{' '}
                            <CKText className="special">
                                {this.state.memberStatus.charAt(0).toUpperCase() + this.state.memberStatus.slice(1)}
                            </CKText>{' '}
                            in {this.state.nextMonth}
                            </CKText>
                        ) : (
                            <View style={{
                                flexDirection: 'row',
                                 display: 'inline-flex',
                                 padding: 8,
                            }}>
                            <View style={{
                              width: 36, height: 36, 
                              borderRadius: 18, 
                              borderWidth: 1, 
                              borderColor: CKColors.white, 
                              backgroundColor: CKColors.white,
                              marginLeft: 16,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignSelf: 'center'
                            }}>
                                <CKIcon width={24} height={24} icon={CKIconType.party_popper_large} />
                            </View>
                            <View style={{flex: 1, textAlign: 'left', marginLeft: 10}}>
                                <CKText className="progress-next-level-text-win">
                                    Congratulations you'll upgrade{' '}
                                </CKText>
                                <CKText className="progress-next-level-text-win">
                                    to{' '}{completedLevel.text}{' '}
                                    in {this.state.nextMonth}!
                                </CKText>
                            </View>
                               
                            </View>
                        ) }

                            <div className="or-divider">
                                <div className="or-container">
                                    <div className="or-line" style={{float: 'right', marginRight: 10}}/>
                                </div>
                                <div>
                                    <CKText className="progress-next-level-text">
                                        or
                                    </CKText>
                                </div>
                                <div className="or-container">
                                    <div className="or-line" style={{float: 'left', marginLeft: 10}}/>
                                </div>
                            </div>

                            <CKText style={{display: 'block', paddingHorizontal: '20px', wordWrap: 'break-word'}}>
                                <CKText style={{display: 'inline'}}>
                                    Spend <CKText className="special" style={{display: 'inline'}}>
                                    {CKStrings.convertToUserCurrency('€')}{Number(nextLevel.max - this.state.LoyaltyBalance).toFixed(2)}
                                    </CKText> {this.state.daysRemaining > 0 ? 'in ' : 'today'}{this.state.daysRemaining > 0 ? this.state.daysRemaining : ''}{this.state.daysRemaining > 0 ? ' day' : ''}{this.state.daysRemaining > 1 ? 's' : ''}{' '}
                                </CKText>
                                <CKText style={{display: 'block'}}>
                                    to upgrade to {nextLevel.text} next month
                                </CKText>
                            </CKText>
                            
                            
                        </CKText>
                    }
                }
            }

            if(this.state.customMessage) {
                message = this.state.customMessage;
            }
        }

        let drawProgressIndicator = () => {
            return <View style={{
                position: 'absolute',
                    ...((progressPercentage >= 15 && progressPercentage < 100.0 / 3) || progressPercentage >= 50.0 ? {right: progressPercentage >= 34 && progressPercentage < 50 ? (
                        (progressPercentage - 34) / 100.0 * totalBarWidth + 3
                    ) : (
                        progressPercentage >= 67 && progressPercentage <= 80 ? (
                            (progressPercentage - 67) / 100.0 * totalBarWidth + 3
                        )
                        : 0
                    )} : {
                        left: progressPercentage >= 100.0 / 3 ? totalBarWidth * .33 : 0
                    }
                ),
                flex: 1,
                paddingLeft: CKPadding.default / 4,
                paddingRight: CKPadding.default / 4
            }}>
            </View>
        }
        
        return super.render(
            <div className="home-container">
                <div className="home-top-section">
                    <div className="intro">
                        <div className="name">Hello {this.state.firstName}</div>

                        {!this.state.memberStatusLoading && !this.state.memberStatus ? 
                            <div style={{
                                position: 'absolute',
                                right: -5,
                                bottom: -10,
                            }}>
                                <CKButton onPress={() => {
                                    this.setState({
                                        showMemberModal: true
                                    })
                                }} custom hover_style={{
                                    opacity: .75
                                }} customChildren={() => {
                                    return <CKIcon width={32} height={32} icon={CKIconType.more_info_white} />
                                }} />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div></div>
                    { !this.state.isSpecialGroup && 
                    <div>
                    <div className="progress-holder">
                        <div className="top-section">
                            Current Membership
                            <div>
                                <CKButton custom hover_style={{
                                    opacity: .75
                                }} default_style={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 3
                                }} customChildren={() => {
                                    return <CKIcon width={28} height={28} icon={CKIconType.more_info_large} />
                                }} onPress={() => {
                                    this.setState({
                                        showMemberInfoModal: true
                                    })
                                }} />
                            </div>
                        </div>

                        <div className="middle-section">

                            {this.state.memberStatusLoading ? 
                                <div style={{
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    marginTop: CKPadding.default / 2
                                }}><CKLoading /></div>
                                :
                            <div className="current-status">{this.state.memberStatus ? this.state.memberStatus.toUpperCase() : 'Pending'}</div>
                        }

                            {
                                // Progress bar on transactions
                            }
                            <View ref={ref => this.progressRef = ref} style={{
                                borderRadius: 15,
                                backgroundColor: CKColors.progressBarEmpty,
                                marginTop: CKPadding.default / 3,
                                overflow: 'hidden',
                                position: 'relative',
                            }}>
                                <View style={{
                                    height: 7,
                                    display: 'flex',
                                    position: 'relative'
                                }}>
                                    <View style={{
                                        width: progressPercentage + '%',
                                        backgroundColor: CKColors.orange,
                                        borderRadius: 16,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        display: 'flex',
                                        position: 'relative'
                                    }}>
                                        {progressPercentage >= 15 ? drawProgressIndicator() : null}
                                    </View>

                                    {progressPercentage < 15 ? drawProgressIndicator() : null}
                                </View>
                                
                                <View style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    top: 0,
                                    flexDirection: 'row',
                                    display: 'flex'
                                }}>
                                    {this.state.levels.map((l, index) => {                                            
                                        return <View key={'indicator_' + index} style={{
                                            flex: 1,
                                            display: 'flex',
                                            borderRight: (index !==this.state.levels.length - 1 ? 2 : 0) + 'px solid ' + CKColors.white
                                        }} />;
                                    })}
                                </View>
                            </View>
                        </div>

                        <div className="bottom-section"/>
                    </div>

                    <div className="message-section">
                        <CKText>
                            {message}
                        </CKText>
                        <CKButton className="ck-button-view-membership" onPress={() => {
                            this.navigateTo(CKNavigator.compareLevels);
                        }} custom>
                            <CKText>
                                Compare Membership Levels 
                            </CKText>
                            <IconArrowViewMore style={{marginBottom: -8}} />
                            
                        </CKButton>

                    </div>

                    

                    </div>
                    }
                </div>

                {this.state.infoCard && this.state.infoCard.enabled && 

                <div class="home-offers-container" style={{
                        marginTop: CKPadding.default,
                        marginLeft: CKPadding.default,
                        marginRight: CKPadding.default,
                        paddingBottom: '0px'
                    }}
                >
                    <CKButton custom 
                        default_style={{
                            width: '100%',
                            padding: 0,
                        }}
                        onPress={() => {
                        this.setState({
                            showInfoCardModal: true
                        })
                    }} >
                        <div style={{
                            paddingTop: CKPadding.default,
                            paddingBottom: CKPadding.default,
                            paddingHorizontal: CKPadding.default,
                            backgroundColor: CKColors.offWhite,
                            borderColor: CKColors.offWhite,
                            borderRadius: CKSizes.radius                        
                        }}>
                            <div style={{float:"left", width:'70%'}}>
                                <div style={{
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                    textAlign: 'left',
                                    marginLeft: CKPadding.default
                                }}>
                                    <span localisable={false} style={{
                                        ...Styles.actBlack,
                                        fontSize: CKSizes.font.larger,
                                        color: CKColors.offBlack
                                    }}>
                                        {this.state.infoCard.title}
                                    </span>
                                </div>
                                <div style={{
                                    flexDirection: 'row',
                                    alignItems: 'left',
                                    marginLeft: CKPadding.default,
                                    paddingTop: CKPadding.default/2,
                                    display: 'flex'
                                }}>
                                    <CKButton no_button reverseUnderline style={{
                                        textTransform: 'uppercase',
                                        fontSize: CKSizes.font.tiny,
                                        color: CKColors.red,
                                        ...Styles.actMedium
                                    }} link>
                                        {'View Reward'}
                                    </CKButton>
                                    
                                    <CKIcon style={{
                                        marginTop: '-5'
                                    }} width={24} height={24} icon={CKIconType.chevron_red} />
                                </div>
                            </div>
                            <div style={{float:"left",
                                paddingLeft: CKPadding.default,
                                paddingRight: CKPadding.default,
                                    alignContent: 'center',     
                                    justifyContent: 'center',                                   
                                    alignItems: 'center'}}>
                                <InforCardStore style={{ alignSelf: "center"}}/>
                            </div>
                            <div style={{clear: "both"}}>

                            </div>
                        </div>
                    </CKButton>
                </div>
}


                <div className="home-main-title">
                    Offers available
                </div>

                <div className="home-offers-container">
                    {/** FUEL OFFERS */}
                    {this.state.offers.fuel.map((offer, index) => {
                        // only show first offer
                        if(index > 0) return null;

                        return <CKOffer
                            key={'offer_' + offer.OfferId}
                            max_value={1}
                            current_value={1}
                            type={offer.rule && offer.rule.icon ? offer.rule.icon : 'fuel'}
                            title={CKStrings.convertToUserCurrency(offer.custom_title)}
                            description={CKStrings.convertToUserCurrency(offer.OfferText)}
                            offer={offer}
                        />
                    })}

                    {/** SURPRISE AND DELIGHT OFFERS */}
                    {this.state.offers.surprise.map((offer, index) => {
                        return <CKOffer
                            marginTop={index > 0 || this.state.offers.fuel.length > 0 ? CKPadding.default : 0}
                            key={'offer_' + offer.OfferId}
                            max_value={1}
                            current_value={1}
                            type={offer.rule && offer.rule.icon ? offer.rule.icon : 'surprise'}
                            title={offer.OfferText}
                            offer={offer}
                            onPress={() => {
                                CKNavigatorStorage.set(offer, 'surprise');
                                this.navigateTo(CKNavigator.offerDetails.replace(":id", offer.OfferId));
                            }}
                        />
                    })}

                    {/** COUNTING OFFERS */}
                    {this.state.offers.counting.map((offer, index) => {
                        return <CKOffer
                            {...(offer.OfferDetails.FreeItemsAvailable && offer.OfferDetails.FreeItemsAvailable.FreeItems >= 1 ? { customViewText: 'Redeem' } : {})}
                            key={'offer_' + offer.OfferId}
                            marginTop={index > 0 || this.state.offers.fuel.length > 0 || this.state.offers.surprise.length > 0 ? CKPadding.default : 0}
                            max_value={offer.OfferDetails.Multiple}
                            current_value={offer.OfferDetails.FreeItemsAvailable && offer.OfferDetails.FreeItemsAvailable.FreeItems >= 1 ? offer.OfferDetails.Multiple : offer.OfferDetails.UnitsEarned}
                            redeem={offer.OfferDetails.FreeItemsAvailable && offer.OfferDetails.FreeItemsAvailable.FreeItems >= 1}
                            type={offer.rule && offer.rule.icon ? offer.rule.icon : ''}
                            description={'Buy' + ' ' + offer.OfferDetails.Multiple + ' ' + 'to get' + ' ' + (offer.OfferDetails.Multiple + 1) + (
                                (offer.OfferDetails.Multiple + 1) ===1 ? 'st' : (
                                    (offer.OfferDetails.Multiple + 1) ===2 ? 'nd' : (
                                        (offer.OfferDetails.Multiple + 1) ===3 ? 'rd' : 'th'
                                    )
                                )
                            ) + ' ' + 'free'}
                            title={offer.OfferText}
                            offer={offer}
                            onPress={() => {
                                CKNavigatorStorage.set(offer, 'counting');
                                this.navigateTo(CKNavigator.offerDetails.replace(":id", offer.OfferId));
                            }}
                        />
                    })}

                    {/** GROUPED OFFERS */}
                    {this.state.offers.view_more.length > 0 ? <CKOffer
                            marginTop={this.state.offers.fuel.length > 0 
                                || this.state.offers.surprise.length > 0
                                || this.state.offers.counting.length > 0 
                                ? CKPadding.default : 0}
                            max_value={1}
                            current_value={1}
                            type={this.state.offers.view_more[0].rule && this.state.offers.view_more[0].rule.icon ? this.state.offers.view_more[0].rule.icon : 'offers'}
                            title={this.state.offers.view_more[0].custom_title}
                            customViewText={'View Offers'}
                            onPress={() => {
                                CKNavigatorStorage.setMemberOffers(this.state.offers.view_more);
                                
                                this.navigateTo(CKNavigator.exclusiveMemberOffers);
                            }}
                        /> : null}

                    {/** OTHER OFFERS */}
                    {this.state.offers.other.map((offer, index) => {
                        return <CKOffer
                            key={'offer_' + offer.OfferId}
                            marginTop={index > 0 || this.state.offers.counting.length > 0 || this.state.offers.view_more.length > 0 || this.state.offers.fuel.length > 0 || this.state.offers.surprise.length > 0 ? CKPadding.default : 0}
                            max_value={1}
                            current_value={1}
                            type={offer.rule && offer.rule.icon ? offer.rule.icon : 'surprise'}
                            title={offer.OfferText}
                            offer={offer}
                            onPress={() => {
                                if(offer.OfferDetails.FreeItemsAvailable && offer.OfferDetails.FreeItemsAvailable.FreeItems >= 1) {
                                    Alert.alert("TODO", "Present info on downloading the app");
                                } else {
                                    CKNavigatorStorage.set(offer, 'other');
                                    this.navigateTo(CKNavigator.offerDetails.replace(":id", offer.OfferId));
                                }
                            }}
                        />
                    })}

                    <CKSpacer />
                </div>
                {this.state.how_extra_works ?
                <SlidingPane
                    className="ck-modal"
                    hideHeader={true}
                    from="bottom"
                    width="calc(100% - 40px)"
                    isOpen={this.state.showMemberInfoModal}
                    onRequestClose={() => {
                        this.setState({ showMemberInfoModal: false });
                    }}
                >
                    <CKText className="modal-header">{this.state.how_extra_works.headline}</CKText>
                    <CKText className="modal-description">{this.state.how_extra_works.description.replace(/\t/g, '')}</CKText>

                    <CKButton className="modal-close" custom onPress={() => {
                        this.setState({
                            showMemberInfoModal: false
                        })
                    }}>
                        <CKIcon width={24} height={24} icon={CKIconType.cancel_grey} />
                    </CKButton>
                </SlidingPane>
                : null }

                <SlidingPane
                    className="ck-modal"
                    hideHeader={true}
                    from="bottom"
                    width="calc(100% - 40px)"
                    isOpen={this.state.showMemberModal}
                    onRequestClose={() => {
                        this.setState({ showMemberModal: false });
                    }}
                >
                    <CKText className="modal-header">{this.state.pending_title}</CKText>
                    <CKText className="modal-description">{this.state.pending_text.replace(/\t/g, '')}</CKText>

                    <CKButton custom className="modal-close" onPress={() => {
                        this.setState({
                            showMemberModal: false
                        })
                    }}>
                        <CKIcon width={24} height={24} icon={CKIconType.cancel_grey} />
                    </CKButton>
                </SlidingPane>
                {this.state.infoCard && this.state.infoCard.enabled ? 
                <SlidingPane
                    className="ck-modal"
                    hideHeader={true}
                    from="bottom"
                    width="calc(100% - 40px)"
                    isOpen={this.state.showInfoCardModal}
                    onRequestClose={() => {
                        this.setState({ showInfoCardModal: false });
                    }}
                >
                    <CKText className="modal-header">{this.state.infoCard.modal_title}</CKText>
                    <CKText className="modal-description">{this.state.infoCard.modal_text.replace(/\t/g, '')}</CKText>

                    <CKButton custom className="modal-close" onPress={() => {
                        this.setState({
                            showInfoCardModal: false
                        })
                    }}>
                        <CKIcon width={24} height={24} icon={CKIconType.cancel_grey} />
                    </CKButton>
                </SlidingPane>
                 : null }
            </div>
        )
    }
}